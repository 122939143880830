<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">
        {{ $l.get("genomemoset-edit.header") }}
      </h3>
      <GenomemosetForm
        :diagnostics="data.diagnostics"
        :genomemoset="data.genomemoset"
        :onSubmit="onSubmit"
        v-if="data && data.genomemoset"
      />
    </div>
  </div>
</template>

<script>
import GenomemosetEditScreenController from "@/adapters/controllers/screen-genomemoset-edit";
import InitGenomemosetEditInteractor from "@/business/genomemoset-edit/init-genomemoset-edit";
import SubmitFormInteractor from "@/business/genomemoset-edit/submit-form";
import GenomemosetForm from "@/application/components/dna/GenomemosetForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "genomemoset-edit",
  components: { GenomemosetForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initGenomemosetEdit: null
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(GenomemosetEditScreenController);

    //{ INTERACTORS
    this.interactors.initGenomemosetEdit = this.$injector.get(
      InitGenomemosetEditInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initGenomemosetEdit.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.data.genomemoset);
    }
  }
};
</script>

<style lang="scss"></style>
